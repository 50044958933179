import React, { useContext, useEffect, useState } from "react";
import { FindDealerContext } from "./context/findDealerContext";
import view from "./findDealer.module.scss";
import MapMarkerIcon from "./assets/mapMarkerIcon";
import sellVehicleMobile from "../../images/sell-your-vehicle-mobile.png"
import sellVehicleDesktop from "../../images/sell-your-vehicle-desktop.png"

const { siteMetadata } = require("../../../gatsby-config");
const googleAPIkey = siteMetadata.googleAPIkey;

const FindDealerMobileHome = (props) => {
  const [formZip, setFormZip] = useState("");

  //Define context
  const findDealerProps = useContext(FindDealerContext);

  /**
   * Define values from findDealerContext
   *
   * @param {method} searchZipCode calls three other functions from context setZipcode, isThereHistory, distanceRef
   * and passes the zipcode searched.
   */

  const { searchZipCode, scrollToListElement } = findDealerProps;

  const keyPress = (e, formZip) => {
    if (e.keyCode === 13) {
      searchZipCode(formZip);
    }
  };

  function holdFormZip(val) {
    setFormZip(val);
  }

  function submission(e, formZip) {
    searchZipCode(formZip);
    e.preventDefault();
    scrollToListElement();
  }

  return (
    <div className={`${view["mobile-search"]} ${props.isSellYourVehicle === true ? view["sellVehicle-mobile"] : "other"}`}>
      {props.isSellYourVehicle
        ? (
          <div className={view["sell-vehicle"]}>
            <picture>
              <source srcset={sellVehicleDesktop} media="(min-width: 600px)"/>
              <img src={sellVehicleMobile} className={view["sell-vehicle__img"]} alt="steps to sell your vehicle" />
            </picture>
          </div>
        )
        : <></>}
    </div>
  );
};

export default FindDealerMobileHome;
